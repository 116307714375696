import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import './Login.css'; // Подключение стилей для компонента Login

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/gmail.readonly');
    provider.addScope('https://www.googleapis.com/auth/gmail.modify');
    provider.addScope('https://www.googleapis.com/auth/drive.labels.readonly');
    provider.addScope('https://www.googleapis.com/auth/drive.readonly');
    provider.addScope('https://www.googleapis.com/auth/drive.admin.labels');
    provider.addScope('https://www.googleapis.com/auth/drive.labels');
    provider.addScope('https://www.googleapis.com/auth/spreadsheets');
    provider.addScope('https://www.googleapis.com/auth/spreadsheets.readonly ');
    

    const auth = getAuth();

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;

      if (token) {
        localStorage.setItem('accessToken', token);
        navigate('/main'); // Переход к основному компоненту после успешного входа
      } else {
        throw new Error('No access token returned from Google auth');
      }
    } catch (error) {
      console.error('Authentication error:', error);
      setError('Ошибка входа. Пожалуйста, попробуйте еще раз.'); // Установка сообщения об ошибке
    }
  };

  return (
    <div className="login-container">
      {error && <p className="error-message">{error}</p>}
      <button onClick={handleLogin} className="login-with-google-btn">
        Войти с Google
      </button>
    </div>
  );
}

export default Login;