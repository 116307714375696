import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function NotionAuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchAccessToken = async () => {
      const query = new URLSearchParams(location.search);
      const code = query.get('code');
      
      if (code) {
        const clientId = '9dda40aa-003f-4916-9970-e3d452c8fa9e';
        const clientSecret = 'secret_wnweAKeXzzsjsTvizfbwdfx9eSt0GODKqf9mhADBzYk';
        const redirectUri = 'http://localhost:3000/notion-callback';

        const response = await fetch('https://api.notion.com/v1/oauth/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
          },
          body: JSON.stringify({
            grant_type: 'authorization_code',
            code,
            redirect_uri: redirectUri,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('notionAccessToken', data.access_token);
          navigate('/main');
        } else {
          console.error('Failed to fetch access token');
        }
      }
    };

    fetchAccessToken();
  }, [location.search, navigate]);

  return <div>Authorizing...</div>;
}

export default NotionAuthCallback;
