import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBGtdQpO-0SAsH-V0giM8iGcj4nLzpUsPI",
    authDomain: "icanai.firebaseapp.com",
    projectId: "icanai",
    storageBucket: "icanai.appspot.com",
    messagingSenderId: "147971657400",
    appId: "1:147971657400:web:f9eb7b196101d5f229ae4d",
    measurementId: "G-BTYDPJ8MCX"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/gmail.readonly');
provider.addScope('https://www.googleapis.com/auth/gmail.modify');
provider.addScope('https://www.googleapis.com/auth/drive.labels.readonly');

const signInWithGoogle = async (navigate) => {
  try {
    const result = await signInWithPopup(auth, provider);
    const token = result.credential.accessToken;
    console.log("Access Token:", token);
    navigate('/main');
  } catch (error) {
    console.error("Authentication error:", error);
  }
}

export { app, auth, signInWithGoogle }; // Экспортируем app, чтобы использовать его в других файлах
