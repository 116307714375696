import React, { useState, useEffect } from 'react';

function SheetModal({ spreadsheetId }) {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    function handleResize() {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const embedUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit?usp=sharing`;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <iframe 
        src={embedUrl}
        style={{ width: '100%', height: dimensions.height - 100, border: 'none' }}  // используем dimensions.height для вычисления высоты
        frameBorder="0"
        allowFullScreen={true}
        title="Google Spreadsheet"
      ></iframe>
    </div>
  );
}

export default SheetModal;
