import React from 'react';
import ReactDOM from 'react-dom';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import EmailModal from './EmailModal';
import SheetModal from './SheetModal';
import './Modal.css';

function BaseModal({ content, onClose, type }) {
    return ReactDOM.createPortal(
      <div className="modal-backdrop" onClick={onClose}>
        <ResizableBox 
          width={1000} 
          height={600} 
          className="modal-content" 
          minConstraints={[300, 300]} 
          maxConstraints={[Infinity, Infinity]} 
          handleSize={[8, 8]}  // размеры "ручек" для изменения размера
          resizeHandles={['se', 'sw', 'ne', 'nw']}  // ручки изменения размера по углам
          onClick={(e) => e.stopPropagation()}  // Предотвращаем всплытие клика, чтобы не вызвать onClose
        >
          <div> {/* Обертка для содержимого */}
            <button onClick={onClose} className="modal-close-btn">Close</button>
            {type === 'drive' ? (
              <SheetModal spreadsheetId={content.id} />
            ) : (
              <EmailModal content={content} />
            )}
          </div>
        </ResizableBox>
      </div>,
      document.body // Рендерим модальное окно напрямую в body
    );
}

export default BaseModal;
