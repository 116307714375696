import React, { useEffect, useState } from 'react';
import './GmailWindow.css';
import BaseModal from './BaseModal';
import gmailLogo from './logo_gmail_2020q4_color_1x_web_96dp.png';
import calendarLogo from './logo_calendar_2020q4_color_1x_web_96dp.png';
import driveLogo from './logo_drive_2020q4_color_1x_web_96dp.png';
import notionLogo from './notion.png';
import githubLogo from './github-mark-white.png';
import slackLogo from './Slack.png';

function GmailWindow({ onEmailDrop }) {
  const [emails, setEmails] = useState([]);
  const [driveFiles, setDriveFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [viewMode, setViewMode] = useState('emails');

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      setError('Access token not found. Please login again.');
      setIsLoading(false);
      return;
    }

    try {
      const listResponse = await fetch('https://www.googleapis.com/gmail/v1/users/me/messages?maxResults=10', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (!listResponse.ok) {
        throw new Error(`Failed to fetch emails: ${listResponse.statusText}`);
      }
      const listData = await listResponse.json();

      const emailsDetails = await Promise.all(listData.messages.map(async (message) => {
        const detailResponse = await fetch(`https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}?format=full`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        if (!detailResponse.ok) {
          throw new Error(`Failed to fetch message details: ${detailResponse.statusText}`);
        }
        return await detailResponse.json();
      }));

      setEmails(emailsDetails);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch emails:', error);
      setError('Failed to load emails. Please try again later.');
      setIsLoading(false);
    }
  };

  const fetchDriveFiles = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error('Access Token not found');
      setError('Login again to retrieve access token');
      setIsLoading(false);
      return;
    }

    const response = await fetch('https://www.googleapis.com/drive/v3/files?corpora=user', {
      headers: { Authorization: `Bearer ${accessToken}` }
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to fetch files: ${errorText}`);
    }

    const filesData = await response.json();
    setDriveFiles(filesData.files);
    setIsLoading(false);
  };

  const handleIconClick = (iconType) => {
    if (iconType === 'drive') {
      fetchDriveFiles();
      setViewMode('drive');
    } else if (iconType === 'notion') {
      setViewMode('notion');
    } else if (iconType === 'github') {
      window.open('https://github.com', '_blank');
    } else if (iconType === 'slack') {
      window.open('https://slack.com', '_blank');
    } else {
      setViewMode('emails');
    }
  };

  const handleDragStart = (e, email) => {
    const emailData = JSON.stringify(email);
    e.dataTransfer.setData("text/plain", emailData);
  };

  const extractNameFromEmail = (emailHeader) => {
    const match = emailHeader.match(/^(.*?)(?=<)/);
    return match ? match[1].trim() : emailHeader;
  };

  const handleEmailClick = (email) => {
    setSelectedItem(email);
    setModalType('email');
  };

  const handleFileClick = (file) => {
    setSelectedItem(file);
    setModalType('drive');
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalType(null);
  };

  const handleNotionAuth = () => {
    window.location.href = 'https://api.notion.com/v1/oauth/authorize?owner=user&client_id=9dda40aa-003f-4916-9970-e3d452c8fa9e&redirect_uri=http://localhost:3000/notion-callback&response_type=code';
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="gmail-window">
      <h3>
        {viewMode === 'emails' ? 'Gmail' :
          viewMode === 'drive' ? 'Google документы' :
            'Интеграция с Notion'}
      </h3>
      {viewMode === 'emails' ? (
        emails.length > 0 ? (
          <div>
            {emails.map(email => (
              <div key={email.id} className="email-item" draggable="true"
                onDragStart={(e) => handleDragStart(e, email)}
                onClick={() => handleEmailClick(email)}>
                <div className="email-header">{extractNameFromEmail(email.payload.headers.find(h => h.name === "From")?.value || "Unknown")}</div>
                <div className="email-subject">{email.payload.headers.find(h => h.name === "Subject")?.value || "No Subject"}</div>
                <div className="email-snippet">{email.snippet || "No Content"}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>No emails to display.</p>
        )
      ) : viewMode === 'drive' ? (
        driveFiles.length > 0 ? (
          driveFiles.map(file => (
            <div key={file.id} className="drive-item" onClick={() => handleFileClick(file)}>
              <h4 className="document-name">{file.name}</h4>
              <p className="document-type">{file.mimeType}</p>
            </div>
          ))
        ) : (
          <p>No drive files to display.</p>
        )
      ) : (
        <div>
          <p></p>
          <button className="notion-auth-button" onClick={handleNotionAuth}>Добавить Notion</button>
        </div>
      )}
      {selectedItem && <BaseModal content={selectedItem} onClose={handleCloseModal} type={modalType} />}
      <div className="vertical-line"></div>
      <div className="icons-container">
        <img src={gmailLogo} alt="Gmail Logo" className="icon" onClick={() => handleIconClick('emails')} />
        <img src={calendarLogo} alt="Calendar" className="icon" />
        <img src={driveLogo} alt="Drive Logo" className="icon" onClick={() => handleIconClick('drive')} />
        <img src={notionLogo} alt="Notion Logo" className="icon" onClick={() => handleIconClick('notion')} />
        <img src={githubLogo} alt="GitHub Logo" className="icon" onClick={() => handleIconClick('github')} />
        <img src={slackLogo} alt="Slack Logo" className="icon" onClick={() => handleIconClick('slack')} />
      </div>
    </div>
  );
}

export default GmailWindow;
