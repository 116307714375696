import React from 'react';
import Sidebar from './Sidebar';
import GmailWindow from './GmailWindow';
import Weekdays from './Weekdays'; // Импорт нового компонента
import './Main.css';

function Main() {
    return (
        <div className="main-container">
            <Sidebar />
            <div className="content">
                <GmailWindow />
            </div>
            <Weekdays /> {/* Добавление Weekdays в разметку */}
        </div>
    );
}

export default Main;
