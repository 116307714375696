import React, { useState } from 'react';
import TaskModal from './TaskModal';
import BaseModal from './BaseModal';
import './Weekdays.css';

function Weekdays() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [draggedEmails, setDraggedEmails] = useState([]);

  const getDaysStartingToday = (weeks = 2) => {
    const today = new Date();
    const totalDays = 7 * weeks;
    return Array.from({ length: totalDays }, (_, i) => {
      const day = new Date(today);
      day.setDate(day.getDate() + i);
      const dayName = day.toLocaleDateString('ru-RU', { weekday: 'long' });
      const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
      const dayDate = day.toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long'
      });
      return {
        name: capitalizedDayName,
        date: dayDate
      };
    });
  };

  const weekdays = getDaysStartingToday();

  const addTask = (task, date) => {
    const newTask = { task, date };
    setTasks([...tasks, newTask]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, date) => {
    e.preventDefault();
    const email = JSON.parse(e.dataTransfer.getData("text/plain"));
    const newDraggedEmail = {
      ...email,
      date,
    };
    setDraggedEmails([...draggedEmails, newDraggedEmail]);
    setSelectedEmail(newDraggedEmail);
  };

  const handleEmailClick = (email) => {
    setSelectedEmail(email);
    setIsModalOpen(true);
  };

  const handleRectangleClick = () => {
    setIsTaskModalOpen(true);
  };

  return (
    <div className="weekdays-container">
      {weekdays.map((day, index) => (
        <div key={index} className="day">
          <div className="day-name">{day.name}</div>
          <div className="day-date">{day.date}</div>
          <div className="rectangle" onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, day.date)} onClick={handleRectangleClick}>
            +
          </div>
          {tasks.filter(t => t.date === day.date).map((t, i) => (
            <div key={i} className="task-rectangle">{t.task}</div>
          ))}
          {draggedEmails.filter(email => email.date === day.date).map((email, i) => (
            <div key={i} className="email-item" onClick={() => handleEmailClick(email)}>
              <div className="email-header"><strong>From:</strong> {email.payload.headers.find(h => h.name === "From")?.value || "Unknown"}</div>
              <div className="email-subject"><strong>Subject:</strong> {email.payload.headers.find(h => h.name === "Subject")?.value || "No Subject"}</div>
              <div className="email-snippet">{email.snippet || "No Content"}</div>
            </div>
          ))}
        </div>
      ))}
      {isModalOpen && selectedEmail && <BaseModal content={selectedEmail} onClose={() => setIsModalOpen(false)} type="email" />}
      {isTaskModalOpen && <TaskModal isOpen={isTaskModalOpen} onClose={() => setIsTaskModalOpen(false)} onSubmit={addTask} />}
    </div>
  );
}

export default Weekdays;
