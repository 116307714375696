import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";

function LogoutButton() {
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate("/");
        } catch (error) {
            console.error('Ошибка при выходе из системы:', error);
        }
    };

    return (
        <button onClick={handleLogout} className="logout-button">
            Выйти
        </button>
    );
}

export default LogoutButton;
