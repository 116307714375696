import React from 'react';

function EmailModal({ content }) {
  if (!content || !content.payload || !content.payload.headers) {
    console.error('Invalid email content received:', content);
    return <div>Email content is not available</div>;
  }
  console.log("Valid email content received:", content);
  const decodeEmailBody = (body) => {
    if (!body) return '<p>No content available</p>';
    try {
      const base64String = body.replace(/-/g, '+').replace(/_/g, '/');
      const decodedBody = atob(base64String);
      return decodeURIComponent(escape(decodedBody));
    } catch (error) {
      console.error('Error decoding email body:', error);
      return '<p>Error displaying content</p>';
    }
  };

  const findHtmlPart = (parts) => {
    for (let part of parts) {
      if (part.mimeType === 'text/html' && part.body && part.body.data) {
        return decodeEmailBody(part.body.data);
      } else if (part.parts) {
        return findHtmlPart(part.parts);
      }
    }
    return '<p>Email body not available</p>';
  };

  const fromHeader = content.payload.headers.find(header => header.name === "From")?.value || "Unknown Sender";
  const dateHeader = content.payload.headers.find(header => header.name === "Date")?.value || "No Date";
  const subjectHeader = content.payload.headers.find(header => header.name === "Subject")?.value || "No Subject";
  const emailBodyHtml = content.payload.parts ? findHtmlPart(content.payload.parts) : decodeEmailBody(content.payload.body.data);

  return (
    <>
      <p><strong>From:</strong> {fromHeader}</p>
      <p><strong>Date:</strong> {dateHeader}</p>
      <p><strong>Subject:</strong> {subjectHeader}</p>
      <div className="email-body" dangerouslySetInnerHTML={{ __html: emailBodyHtml }} />
    </>
  );
}

export default EmailModal;
