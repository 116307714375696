import React from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInbox, faCalendarDay, faCalendarAlt, faTags, faHouse } from '@fortawesome/free-solid-svg-icons';
import LogoutButton from './LogoutButton';

function Sidebar() {
  return (
    <div className="sidebar">
      <h2>iCan</h2>
      <ul>
        <li><FontAwesomeIcon icon={faSearch} className="fa-icon" /> Поиск</li>
        <li><FontAwesomeIcon icon={faInbox} className="fa-icon" /> Входящие</li>
        <li><FontAwesomeIcon icon={faCalendarDay} className="fa-icon" /> Сегодня</li>
        <li><FontAwesomeIcon icon={faCalendarAlt} className="fa-icon" /> Предстоящее</li>
        <li><FontAwesomeIcon icon={faTags} className="fa-icon" /> Фильтры и метки</li>
      </ul>
      <div className="projects">
        <h2>Категория задач</h2>
        <ul>
          <li><FontAwesomeIcon icon={faHouse} className="fa-icon" /> #работа</li>
        </ul>
      </div>
      <LogoutButton />
    </div>
  );
}

export default Sidebar;
