import React, { useState } from 'react';
import './TaskModal.css';  // Подтвердите, что стили правильно подключены

function TaskModal({ isOpen, onClose, onSubmit }) {
  const [task, setTask] = useState('');
  const [date, setDate] = useState('');

  if (!isOpen) return null;

  return (
    <div className="taskmodal-backdrop">
      <div className="taskmodal-content">
        <button onClick={onClose} className="taskmodal-close-btn">Закрыть</button>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit(task, date);
          onClose();
        }}>
          <div className="input-group">
            <label className="input-label">Enter Task</label>
            <input
              type="text"
              placeholder="Enter task"
              value={task}
              onChange={(e) => setTask(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label className="input-label">Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <button type="submit">Add Task</button>
        </form>
      </div>
    </div>
  );
}

export default TaskModal;
